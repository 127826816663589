import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import React from "react";
import { useTheme } from "@emotion/react";
import Logo from "../../Logo/image_brand_card_1.png";

const Sidebar = ({ drawerOpen, handleDrawerClose, pages }) => {
  const theme = useTheme();
  return (
    <Drawer
      variant="temporary"
      anchor="left"
      open={drawerOpen}
      onClose={handleDrawerClose}
    >
      <Box
        sx={{
          width: 240,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          padding: 1,
        }}
      >
        <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
          <img
            src={Logo}
            alt=""
            loading="lazy"
            style={{ width: "90%", height: "90%", margin: "0 auto" }}
          />
        </Box>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "rtl" ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </Box>
      <Divider />
      <List>
        {pages.map((page, index) => (
          <ListItem key={index} disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                textDecoration: "none",
                minHeight: 48,
                justifyContent: "center",
                px: 2.5,
              }}
              component={NavLink}
              to={page.path}
            >
              <ListItemText primary={page.name} sx={{ opacity: 1 }} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

export { Sidebar };
