import React, {useEffect} from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import ConstructionCard from "./constructionCards";
import { useGetConstructionDetails } from "../../helperHooks/getDetails";
import PaperContainer from "src/components/PaperContainer/PaperContainer";

const ConstructionPage = () => {
  const { data, loading, error } = useGetConstructionDetails();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PaperContainer>
      <Box sx={{ width: "100%", px: 2, mt: 2 }}>
        <Typography variant="h4" align="center" py={2} gutterBottom>
          Homely Projects
        </Typography>
        <Typography variant="body1" align="center" paragraph>
          We excel in handling comprehensive trunk projects, providing
          end-to-end solutions that encompass planning, design, execution, and
          project management. Our commitment to quality ensures that every
          project is delivered with precision and excellence. Whether it's
          creating dream homes or state-of-the-art commercial spaces, Homely
          Buildofiy stands as a reliable partner for all construction needs. Our
          experienced team ensures that every structure reflects the highest
          standards of craftsmanship.
        </Typography>
        <Grid container spacing={4} justifyContent="center" my={2}>
          {data?.map((data) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              display="flex"
              justifyContent="center"
              key={data.id}
            >
              <ConstructionCard {...data} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </PaperContainer>
  );
};

export default ConstructionPage;
