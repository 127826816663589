import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { ThemeProvider } from "@mui/styles";
import theme from "./components/Theme/theme";

ReactDOM.render(
  <ThemeProvider theme={theme}>
  <App/>
  </ThemeProvider>,
  document.getElementById('root')
);

