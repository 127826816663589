import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Box, Typography, Button } from "@mui/material";
import { UseIsAboveSm } from "src/helperHooks";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { makeStyles } from "@mui/styles";
import sample from "../../Logo/Launching Soon.mp4";

const useStyles = makeStyles(() => ({
  videoContainer: {
    position: "relative",
    height: "100%",
  },
  video: {
    width: "100%",
    height: "100%",
    objectFit: "fill",
  },
}));

const Fmain = ({ data, loading }) => {
  const isAboveSm = UseIsAboveSm();
  const history = useHistory();
  const classes = useStyles();

  const baseImageBucketUrl = process.env.REACT_APP_S3_IMAGE_BUCKET_URL

  return (
    <Box sx={{ position: "relative", maxWidth: "100%", margin: "auto" }}>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          loop={true}
          autoplay={{ delay: 7000, disableOnInteraction: false }}
          modules={[Autoplay, Pagination, Navigation]}
          pagination={{ clickable: true }}
        >
          {data?.map((item, index) => (
            <SwiperSlide key={index}>
              {item.displayDetailsPageFlag ? (
              <Box
                sx={{
                  position: "relative",
                  padding: "20px",
                  textAlign: "center",
                  backgroundImage: `url(${baseImageBucketUrl}${item.projectImage})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  height: "200%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  color: "#fff",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    padding: { xs: "10px", sm: "15px", md: "20px" },
                    borderRadius: "10px",
                    maxWidth: { xs: "90%", sm: "80%", md: "600px" },
                    width: "100%",
                    textAlign: "center",
                    marginLeft: { xs: "0", md: "auto" },
                    marginTop: { xs: "5px", sm: "20px", md: "2rem" },
                  }}
                >
                  <Typography
                    variant="h3"
                    sx={{ fontSize: { xs: "1.5rem", md: "2.5rem" } }}
                  >
                    {item.projectTitle}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      marginY: "20px",
                      fontSize: { xs: "0.875rem", md: "1rem" },
                    }}
                  >
                    {item.projectInfo
                      ? isAboveSm
                        ? item.projectInfo.slice(0, 500)
                        : item.projectInfo.substring(0, 100)
                      : item.status}
                    ...
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ marginX: "10px" }}
                    onClick={() =>
                      history.push(`/real-estate/${item.id}`, {
                        property: item,
                        loading: loading,
                      })
                    }
                  >
                    View Details
                  </Button>
                </Box>
              </Box>
              ): <Box>
                  <Box sx={{color: 'primary.main'}}>
                    <Typography variant="h4" textAlign={"center"}>{item.projectTitle} will be {item.status} !!!</Typography>
                  </Box> 
                  <Box className={classes.videoContainer}>
                    <video className={classes.video} autoPlay loop muted>
                      <source src={sample} type="video/mp4" />
                    </video>
                  </Box>
              </Box>
               }
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </Box>
  );
};

export default Fmain;
