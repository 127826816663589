import fetchData from "./fetchNetworkRequest"

// const apiIp = process.env.REACT_APP_API_PUBLIC_AP
const apiIp = process.env.REACT_APP_API_PUBLIC_AP
const secureApiIp = process.env.REACT_APP_API_PUBLIC_SECUREAP
export const useGetConstructionDetails =  () => {
    // const url = `${apiIp}:3001/api/constructions`
    const url = `${secureApiIp}/api/constructions`
    const { data  , loading, error } = fetchData(url);
    return { data: data?.details, loading, error };

}
export const useGetRealEstateDetails =  () => {
    // const url = `${apiIp}:3001/api/real-estate`
    const url = `${secureApiIp}/api/real-estate`
    const { data  , loading, error } = fetchData(url);
    return { data: data?.details, loading, error };
    
}
export const useGetInteriorDetails =  () => {
    const url = `${secureApiIp}/api/interior`
    const { data  , loading, error } = fetchData(url);
    return { data: data?.details, loading, error };
}