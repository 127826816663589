import React, { useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import Lightbox from 'react-awesome-lightbox';
import 'react-awesome-lightbox/build/style.css';


const ProjectGallery = ({gallery}) => {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);

  const handleImageClick = (index) => {
    setCurrentImage(index);
    setLightboxOpen(true);
  };
  
  const baseImageBucketUrl = process.env.REACT_APP_S3_IMAGE_BUCKET_URL

  return (
    <Box py={2}>
      <Typography variant="h5" gutterBottom>
        Project Gallery
      </Typography>
      <Grid container spacing={2}>
        {gallery.map((src, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <img
              src={`${baseImageBucketUrl}${src}`}
              alt={`Gallery image ${index + 1}`}
              style={{ width: '100%', height: 'auto', borderRadius: "5px", cursor: 'pointer' }}
              onClick={() => handleImageClick(index)}
            />
          </Grid>
        ))}
      </Grid>
      {lightboxOpen && (
        <Lightbox
          images={gallery.map(src => ({ url: `${baseImageBucketUrl}${src}` }))}
          startIndex={currentImage}
          onClose={() => setLightboxOpen(false)}
        />
      )}
    </Box>
  );
};

export default ProjectGallery;