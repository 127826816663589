import React, {useEffect} from "react";
import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useGetRealEstateDetails } from "../../helperHooks/getDetails";
import PaperContainer from "src/components/PaperContainer/PaperContainer";
import defaultImage from "src/components/image/Image_not_available.png";

const RealEstate = () => {
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const baseImageBucketUrl = process.env.REACT_APP_S3_IMAGE_BUCKET_URL

  const { data: realestate } = useGetRealEstateDetails();
  return (
    <PaperContainer>
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12}>
          <Box px={2} mx="auto" textAlign="center" py={2}>
            <Typography variant="h4" py={2}>
              Real Estate
            </Typography>
            <Typography variant="body1" align="center" paragraph>
              Driven by a passion for creating sustainable communities, we have
              ventured into real estate development. Our focus is on crafting
              living spaces that blend modern amenities with eco-friendly
              practices, fostering a harmonious balance between nature and
              infrastructure.
            </Typography>
          </Box>
        </Grid>
        {realestate?.map((property) => (
          <Grid
            item
            xs={10}
            sm={6}
            md={4}
            key={property.id}
            justifyContent="center"
            display="flex"
          >
            <Card
              sx={{
                cursor: "pointer",
                maxWidth: "350px",
                borderRadius: 5,
                boxShadow: "10px 10px 8px rgb(175, 174, 174)",
              }}
            >
              <CardContent>
                <Typography variant="h4" textAlign={"center"} color="textSecondary">
                  {property.status}
                </Typography>
              </CardContent>
              <CardMedia
                component="img"
                image={property.projectImage ? `${baseImageBucketUrl}${property.projectImage}` : defaultImage}
                alt={property.projectTitle}
                onClick={() =>
                  history.push(`/real-estate/${property.id}`, { property })
                }
              />
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  {property.projectTitle}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Location: {property.location}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Size: {property.area ? property.area : "NA"}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </PaperContainer>
  );
};

export default RealEstate;
