import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import { Pagination, Navigation, Scrollbar } from 'swiper/modules';

import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';

import dodle1 from "src/components/Logo/Book-With-Us.svg";
import dodle2 from "src/components/Logo/Meet-Our_Architechs.svg";
import dodle3 from "src/components/Logo/Finalize Design.svg";
import dodle4 from "src/components/Logo/Track Your Construction.svg";
import dodle5 from "src/components/Logo/settle in.svg";

const useStyles = makeStyles((theme) => ({
  swiper: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  swiperSlide: {
    height: '100%',
  },
  swiperSlideImg: {
    objectFit: 'cover',
  },
  navigationButton: {
    height:"100%",
    color: '#000', // Change color if needed
    // position: 'absolute',
    '&.swiper-button-next': {
      // right: '10px', // Adjust position
      top: '20%', // Adjust position
    },
    '&.swiper-button-prev': {
      // left: '10px', // Adjust position
      top: '20%', // Adjust position
    },
  },
}));

const homelyWorkSteps = [
  {
    id: "Step 1",
    title: "Book With us",
    description: "Embark on your journey to a dream home with us by simply booking your spot today. Whether you prefer the ease of our website, a quick call, or a friendly chat on WhatsApp, we're here to make your home construction journey as smooth as possible. Let's build something beautiful together",
    image: dodle1,
  },
  {
    id: "Step 2",
    title: "Meet our Architect",
    description:
      "Step into a world where your dreams become architectural masterpieces. At Homely, we don't just build houses, we craft personalized sanctuaries that reflect your essence. Our team of visionary architects and designers blend artistry with innovation to sculpt spaces that resonate with your soul.",
    image: dodle2,
  },
  {
    id: "Step 3",
    title: "Finalize Design ",
    description:
      "Our team designs your dream home based on your preferences, allowing real-time adjustments in during planning. Once the design is finalized, we'll work out a budget to turn your vision into reality",
    image: dodle3,
  },
  {
    id: "Step 4",
    title: "Track Your Construction",
    description:
      "As the construction phase commences, our mobile application keeps you connected. Monitor daily advancements from anywhere, guaranteeing that your dream home is evolving to meet your expectations.",
    image: dodle4
  },
  {
    id: "Step 5",
    title: "Settle In",
    description:
      "After conducting more than 1000 stringent quality checks, we guarantee the delivery of your home within the planned budget and schedule. Presenting you with the keys to your happiness is a source of great pride for us.",
    image: dodle5
  },
];

const title = "Construction"
const imageUrl =
  "https://www.michaelmolloy.co.uk/construction-photography/photographs/large/construction-site.jpg"
const description =
  "Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit beatae labore dignissimos corrupti minus reiciendis dolore ea molestias alias numquam."

export default function HowWeWork() {
  const classes = useStyles();
  return (
    <>
      <Swiper
        scrollbar={{ hide: true }}
        navigation={{
          // nextEl: '.swiper-button-next',
          // prevEl: '.swiper-button-prev',
        }}
        modules={[Pagination, Navigation, Scrollbar]}
        className={classes.swiper}
      >
        {homelyWorkSteps.map((el) => (
          <SwiperSlide key={el.id} className={classes.swiperSlide}>
            <Box >
              <img src={el.image} alt={`Step ${el.id}`} width="80%" />
              <Typography variant="h6">{el.title}</Typography>
              <Typography>{el.description}</Typography>
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
      {/* <div className={`swiper-button-next ${classes.navigationButton}`}></div>
      <div className={`swiper-button-prev ${classes.navigationButton}`}></div> */}
    </>
  );
}
