import React, { useState } from "react";
import {
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom/cjs/react-router-dom";
import HomePage, { Test1 } from "./Views/HomePage";
import Navbar from "./components/NavigationBar/Navbar";
import ContactFormDialog from "./components/InquaryDialog/InquaryDialog";
import { makeStyles } from "@mui/styles";
import InteriorPage from "./Views/InteriorPage/InteriorPage";
import AboutUsSection2 from "./Views/AboutUs/AboutUs2";
// import { Button } from "@mui/material";
import {  Box, Button } from '@mui/material';
import { UseIsAboveSm } from "./helperHooks";
import RealEstate from "./Views/RealEstate/RealEstate";
import REDetails from "./components/RealEsateSection/RealEstateDetails";
import ContructionDetails from "./components/ConstructionSection/constructionDetails";
import ConstructionPage from "./Views/ConstructionPage/CounstructionHome";
import Footer from "./components/Footer/Footer2";

export const useFormState = () => {
  const [open, setIsOpen] = React.useState(false);
  return [open, setIsOpen];
};

const App = () => {
  // const isAboveSm = UseIsAboveSm();
  const [isFormOpen, setIsFormOpen] = useState(false);

  return (
    <div>
      <Router>
        <ContactFormDialog
          isFormOpen={isFormOpen}
          setIsFormOpen={setIsFormOpen}
        />
        <Navbar setIsFormOpen={setIsFormOpen} />
        <div>
          {!UseIsAboveSm() && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => setIsFormOpen(true)}
              style={{
                position: "fixed",
                bottom: "10vh",
                right: "0",
                transform: "rotate(90deg)",
                transformOrigin: "80%",
                padding: "4px",
              }}
              sx={{ padding: "4px" }}
            >
              Contact Us
            </Button>
          )}
          <Switch>
            <Route exact path="/">
              <HomePage />
            </Route>
            <Route path="/portfolio/:id">
           
            </Route>
            <Route path="/construction/:id">
              <ContructionDetails />
            </Route>
            <Route path="/construction">
             <ConstructionPage />
            </Route>
            <Route path="/interior">
              <InteriorPage />
            </Route>
            <Route path="/real-estate/:id">
              <REDetails/>
            </Route>
            <Route path="/real-estate">
              <RealEstate/>
            </Route>
          
            <Route path="/about-us">
              <AboutUsSection2 setIsFormOpen={setIsFormOpen} />
            </Route>
          </Switch>
            <Box>
              <Footer/>
            </Box>
        </div>
      </Router>
    </div>
  );
};

export default App;
