import React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

const PaperContainer = ({ children }) => {
  return (
    <Grid container spacing={2}  sx={{justifyContent: "center"}} >
      <Grid item xs={12} md={10} xl={8} >
        <Paper elevation={0}  >
          {children}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default PaperContainer;
