import React, { useState } from 'react';
import { Box, Typography, Grid, Paper } from '@mui/material';
import Lightbox from 'react-awesome-lightbox';
import 'react-awesome-lightbox/build/style.css';

const Gallery = ({gallery}) => {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);

  const handleImageClick = (index) => {
    setCurrentImage(index);
    setLightboxOpen(true);
  };

  const baseImageBucketUrl =  process.env.REACT_APP_S3_IMAGE_BUCKET_URL

  return (
    <Box my={2}>
      <Typography variant="h4" align="center" gutterBottom>Gallery</Typography>
      <Grid container spacing={2} justifyContent="center">
        {gallery.map((image, index) =>  {
        return  <Grid item xs={12} sm={6} md={3} key={index}>
            <Paper elevation={3}>
              <img src={`${baseImageBucketUrl}${image}`} alt={`Gallery Image ${index + 1}`} style={{ width: '100%', maxHeight: 'auto' }} onClick={() => handleImageClick(index)}/>
            </Paper>
          </Grid>
})}
      </Grid>
      {lightboxOpen && (
        gallery.length === 1 ? (
          <Lightbox
            image={`${baseImageBucketUrl}${gallery[0]}`}
            onClose={() => setLightboxOpen(false)}
          />
        ) : (
          <Lightbox
            images={gallery.map(src => ({ url: `${baseImageBucketUrl}${src}` }))}
            startIndex={currentImage}
            onClose={() => setLightboxOpen(false)}
          />
        )
      )}
    </Box>
  );
};

export default Gallery;