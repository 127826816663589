import React, {useEffect} from "react";
import { Grid, Box, Typography, Button, Divider, Paper } from "@mui/material";
import PaperContainer from "../../components/PaperContainer/PaperContainer";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { makeStyles } from "@mui/styles";
import sample from "../../Logo/Homely Logo 2.mp4";

const useStyles = makeStyles(() => ({
  videoContainer: {
    position: "relative",
    height: "100%",
  },
  video: {
    width: "100%",
    height: "100%",
    objectFit: "fill",
  },
}));

const AboutUsSection2 = (props) => {
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <PaperContainer>
      <Box px={2}>
        <Grid
          container
          columnSpacing={1}
          rowSpacing={{ xs: 2, md: 6 }}
          my={{ xs: 0.5, md: 1 }}
        >
          <Grid item xs={12}>
            <Typography variant="h4" textAlign={"center"}>
              About Us
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <Box>
              <Typography variant="h6" textAlign={"center"}>
                Welcome to Homely Buildofiy Pvt Ltd
              </Typography>
              <Typography variant="subtitle1" textAlign={"center"}>
                Your Trusted Partner in Comprehensive Construction Solutions &
                Real-Estate
              </Typography>
              <Typography variant="h6" textAlign={"center"} py={3}>
                Incorporated on September 17, 2017, and established on the
                foundation day of November 9, 2017, Homely Buildofiy Pvt Ltd has
                been a beacon of excellence in the realm of construction and
                real estate development.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" textAlign={"center"}>
              Key Offerings
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <Typography variant="h6" textAlign={"left"}>
                Trunky Project Expertise
              </Typography>
              <Typography variant="subtitle1" textAlign={"left"} py={1}>
                We excel in handling comprehensive trunk projects, providing
                end-to-end solutions that encompass planning, design, execution,
                and project management. Our commitment to quality ensures that
                every project is delivered with precision and excellence.
                Whether it's creating dream homes or state-of-the-art commercial
                spaces, Homely Buildofiy stands as a reliable partner for all
                construction needs. Our experienced team ensures that every
                structure reflects the highest standards of craftsmanship.
              </Typography>

              <Typography variant="h6" textAlign={"left"}>
                Interior Solutions
              </Typography>
              <Typography variant="subtitle1" textAlign={"left"} py={1}>
                Elevate your living and working spaces with our bespoke interior
                solutions. From conceptualization to execution, our interior
                design services add a touch of sophistication and functionality
                to every environment.
              </Typography>

              <Typography variant="h6" textAlign={"left"}>
                Real Estate Developer
              </Typography>
              <Typography variant="subtitle1" textAlign={"left"} py={1}>
                Driven by a passion for creating sustainable communities, we
                have ventured into real estate development. Our focus is on
                crafting living spaces that blend modern amenities with
                eco-friendly practices, fostering a harmonious balance between
                nature and infrastructure.
              </Typography>

              <Box sx={{ textAlign: "center" }} py={2}>
                <Button
                  variant="contained"
                  sx={{ borderRadius: 2 }}
                  onClick={() => history.push("/construction")}
                >
                  View Projects
                </Button>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box className={classes.videoContainer}>
              <video className={classes.video} autoPlay loop muted>
                <source src={sample} type="video/mp4" />
              </video>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <Typography variant="h6" textAlign={"left"}>
                Let's Build Your Homely Haven
              </Typography>
              <Typography variant="subtitle1" textAlign={"left"} py={1}>
                Embark on your construction journey with Homely Buildofiy Pvt
                Ltd. Contact us today, and let's collaborate to turn your vision
                into a reality.
              </Typography>
              <Typography variant="h6" textAlign={"left"}>
                Our Promise
              </Typography>
              <Typography variant="subtitle1" textAlign={"left"}>
                At Homely Buildofiy, every project is more than a construction
                task; it's a promise to deliver spaces that inspire, endure, and
                enchant. We are dedicated to exceeding your expectations and
                ensuring your journey with us is as memorable as the spaces we
                create.
              </Typography>
              <Box py={2} textAlign={{ xs: "center" }}>
                <Button
                  variant="contained"
                  sx={{ borderRadius: 2, alignItems: "left" }}
                  onClick={() => props.setIsFormOpen(true)}
                >
                  Contact Us
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <Typography variant="h6" textAlign={"center"}>
                Recognition
              </Typography>
              <Typography variant="subtitle1" textAlign={"center"}>
                We are proud to be recognized by Start-up Odisha and Start-up
                India, a testament to our commitment to innovation,
                entrepreneurship, and contributing to the growth of the nation.
                Join us at Homely Buildofiy Pvt Ltd as we continue to shape the
                future of construction and real estate development. Experience
                excellence, innovation, and reliability in every project we
                undertake.
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} py={4}>
          <Box sx={{ width: "100%", height: "100%" }}>
            <iframe
              width="100%"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              src="https://maps.google.com/maps?width=100%25&amp;height=400&amp;hl=en&amp;q=Old%20NH-6%20Near%20Bandutikra%20Chowk,%20Bargarh,%20Odisha%20768028+(Homely)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            >
              <a href="https://www.google.com/maps/place/Homely/@21.3431487,83.6153975,15z/data=!4m6!3m5!1s0x3a214a983a402b0f:0xac655fdc7c29f1b8!8m2!3d21.3431487!4d83.6153975!16s%2Fg%2F11fzwgxg9c?entry=ttu">
                Homely
              </a>
            </iframe>
          </Box>
        </Grid>
      </Box>
    </PaperContainer>
  );
};

export default AboutUsSection2;
