import * as React from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { LoadingButton } from "@mui/lab";
import { UseIsAboveSm } from "../../helperHooks";
import axios from "axios";
export default function ContactFormDialog({isFormOpen : open, setIsFormOpen:setOpen}) {
  // const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const apiIp = process.env.REACT_APP_API_PUBLIC_AP

  const handleFormSubmit = async (formData) => {
    const { name, email, phone, description } = formData;
    setLoading(true);
    try {
      const res = await axios.post(
        `${apiIp}/api/email/send-email`,
        { email, name, phoneNo: phone, description }
      );

      const updatesheet = await axios.post(
        `${apiIp}/api/spreedsheet/sheet-data-update`,
        { email, name, phoneNo: phone, description }
      );
    } catch (err) {
    } finally {
      setLoading(false);
    }
    handleClose();
  };

  //panding -> add a popup on submit shwing failure or success

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            handleFormSubmit(formJson);
          },
          style: {
            width: "400px",
            height: "550px",
            borderRadius: "30px",
          },
        }}
      >
        <DialogTitle
          style={{
            fontSize: "1.25rem",
            lineHeight: "1.25rem",
            letterSpacing: "0.05em",
            fontWeight: "bold",
            color: "rgba(87, 83, 78, 1)",
            textAlign: "center",
          }}
        >
          Contact Us
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{ fontSize: "0.9rem", textAlign: "center" }}
          >
            Submit Your Details and we will reach you out soon...
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="name"
            label="Name"
            type="text"
            fullWidth
            variant="standard"
            style={{ marginBottom: "30px" }}
          />
          <TextField
            required
            margin="dense"
            id="email"
            name="email"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
            style={{ marginBottom: "30px" }}
          />
          <TextField
            required
            margin="dense"
            id="phone"
            name="phone"
            label="Phone"
            type="phone"
            fullWidth
            variant="standard"
            style={{ marginBottom: "30px" }}
          />
          <TextField
            required
            margin="dense"
            id="description"
            name="description"
            label="Description"
            type="text"
            fullWidth
            variant="standard"
            style={{ marginBottom: "30px" }}
          />
        </DialogContent>
        <DialogActions>
          <LoadingButton loading={loading} onClick={handleClose} color="secondary">
            {" "}
            Cancel
          </LoadingButton>
          <LoadingButton loading={loading} type="submit" >
            {" "}
            Submit
          </LoadingButton>
          {/* remove once verify everything is working as expected */}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

