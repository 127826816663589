import React, { cloneElement, useEffect, useState } from "react";
import {
  AppBar,
  Box,
  Button,
  CssBaseline,
  IconButton,
  Toolbar,
  useScrollTrigger,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import PropTypes from "prop-types";
import Logo from "../../Logo/HOMELY.png";
import { NavLink, useLocation } from "react-router-dom";
import { Sidebar } from "../SideBar/Sidebar";
import { UseIsAboveSm } from "../../helperHooks";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: (props) => (props.elevation > 0 ? "#E5E5E5" : "transparent"),
    padding: "1px 24px",
    [theme.breakpoints.down('md')]: {
      padding: "1px 4px",  
    },
    top: 0,
    zIndex: 999,
    transition: "all 0.1s ease",
  },
  logoBox: {
    display: "none",
    [theme.breakpoints.up('md')]: {
      display: "flex",
    },
  },
  logo: {
    cursor: "pointer",
    maxWidth: "50%",
  },
  menuButton: {
    flexGrow: 1,
    display: "flex",
    [theme.breakpoints.up('md')]: {
      display: "none",
    },
  },
  smallLogo: {
    cursor: "pointer",
    maxWidth: "40%",
    maxHeight: "5%",
  },
  navLinks: {
    flexGrow: 1,
    display: "none",
    marginLeft: "2rem",
    [theme.breakpoints.up('md')]: {
      display: "flex",
    },
  },
  navButton: {
    // my: 2,
    minWidth:"120px",
    color: "black",
    display: "block",
    textAlign: "center",
  },
}));

function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });
  return cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

const Navbar = (props) => {
  const location = useLocation();
  const [elevation, setElevation] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const history = useHistory();
  const classes = useStyles({ elevation });

  const pages = [
    { name: "Home", path: "/" },
    { name: "Construction", path: "/construction" },
    { name: "Interior", path: "/interior" },
    { name: "Real-Estate", path: "/real-estate" },
    { name: "About Us", path: "/about-us" },
  ];

  const handleScroll = () => {
    setElevation(window.scrollY > 0 ? 4 : 0);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    // Close drawer when location changes
    setDrawerOpen(false);
  }, [location.pathName]);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <ElevationScroll elevation={elevation} {...props}>
        <AppBar position="sticky" className={classes.appBar}>
          <Toolbar disableGutters>
            <Box className={classes.logoBox}>
              <img
                src={Logo}
                alt=""
                loading="lazy"
                onClick={() => history.push("/")}
                className={classes.logo}
              />
            </Box>
            <Box className={classes.menuButton} onClick={() => history.push("/")}>
              <IconButton
                size="large"
                color="black"
                aria-label="menu"
                onClick={handleDrawerOpen}
              >
                <MenuIcon />
              </IconButton>
            </Box>
            <Box className={classes.menuButton}>
              <img
                src={Logo}
                alt=""
                loading="lazy"
                onClick={() => history.push("/")}
                className={classes.smallLogo}
              />
            </Box>
            <Box className={classes.navLinks}>
              {pages.map((page) => (
                <Button
                  component={NavLink}
                  to={page.path}
                  key={page.name}
                  className={classes.navButton}
                >
                  {page.name}
                </Button>
              ))}
            </Box>
            {UseIsAboveSm() && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => props.setIsFormOpen(true)}
              >
                Contact Us
              </Button>
            )}
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Sidebar
        drawerOpen={drawerOpen}
        handleDrawerClose={handleDrawerClose}
        pages={pages}
      />
    </React.Fragment>
  );
};

export default Navbar;