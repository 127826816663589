import React from 'react';
import { Box, Typography, Grid } from '@mui/material';

const DetailSection = ({projectType, location, area, status, description}) => {
  
  return (
    <Box py={2}>
      <Typography variant="h4" align="center" gutterBottom>About</Typography>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={3}>
          <Typography variant="body1" align="center">Project Type</Typography>
          <Typography variant="h6" align="center">{projectType}</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography variant="body1" align="center">Location</Typography>
          <Typography variant="h6" align="center">{location}</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography variant="body1" align="center">Area</Typography>
          <Typography variant="h6" align="center">{area}</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography variant="body1" align="center">Project Status</Typography>
          <Typography variant="h6" align="center">{status}</Typography>
        </Grid>
      </Grid>
      {description?.length > 0 && 
      <Box mt={2} py={2}>
        <Typography variant="body1" paragraph>
        {description}
        </Typography>
      </Box>
      }
      </Box>
  );
};

export default DetailSection;