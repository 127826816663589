import React from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";

const TopSection = ({ image, typeOfBuilding, projectType }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      py={2}
      sx={{
        backgroundImage: `url(${image})`,
        backgroundSize: "cover",
        height: "500px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography
        variant="h4"
        sx={{ color: "white", textShadow: "2px 2px 4px rgba(0,0,0,0.6)", maxWidth: "75%", textAlign: isSmallScreen ? 'center' : 'left' }}
      >
        {projectType}
        {isSmallScreen ? <br /> : '-'}
        {typeOfBuilding}
      </Typography>
    </Box>
  );
};

export default TopSection;