import React from "react";
import { motion } from "framer-motion";
import {
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useInView } from "react-intersection-observer";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const OfferCard = ({ index, title, imageUrl, description, redirectPath }) => {

  const history = useHistory();
  // Use the above as props
  const isEven = index % 2 === 0;
  const isDesktop = useMediaQuery("(min-width:600px)");

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const animationVariants = {
    hidden: {
      opacity: 0,
      y: isEven ? 50 : -50,
      transition: {
        type: "spring",
        damping: 20, // Spring Smoothness
        stiffness: 300, // Spring Stiffness(high value less stiff)
      },
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        ease: "easeInOut",
        duration: 1,
        delay: index / 10,
        when: "beforeChildren", // Determines when to apply the transition
        staggerChildren: 0.1, // Delay in animating children
      },
    },
    exit: {
      opacity: 0,
      y: isEven ? -50 : 50,
      transition: {
        ease: "easeInOut",
        duration: 0.5,
      },
    },
  };

  return (
    <motion.div
    ref={ref}
    variants={isDesktop ? animationVariants : null}
    initial="hidden"
    animate={inView ? "visible" : "hidden"}
    >
      <Card
        sx={{
          maxWidth: 350,
          minHeight: 400,
          cursor: "pointer",
          "&:hover": {
            "& .card-media-image": {
              transform: "scale(1.1)",
            },
          },
          borderRadius: 2,
          boxShadow: '10px 10px 8px rgb(175, 174, 174)',
        }}
      >
        <CardHeader title={title} align="center" />
        <CardMedia
          className="card-media-image"
          component="img"
          // height="190"
          image={imageUrl}
          alt={title}
          sx={{
            objectFit: "contain",
            padding: 2,
            borderRadius: 5,
            transition: "all 0.3s ease-in-out",
          }}
          onClick={() =>
            history.push(redirectPath)
          }
        />
        <CardContent align="center">
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
        </CardContent>
      </Card>
    </motion.div>
  );
};

export default OfferCard;
