import React, { useEffect } from "react";
import { Box, Container } from "@mui/material";
import TopSection from "./Details/TopSection";
import DetailSection from "./Details/DetailSection";
import Gallery from "./Details/GallarySection";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import PaperContainer from "../PaperContainer/PaperContainer";

const ContructionDetails = () => {

  const baseImageBucketUrl = process.env.REACT_APP_S3_IMAGE_BUCKET_URL

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {state} = useLocation();
  const {image, location, area,status, typeOfBuilding,projectType , details } = state ;
  return (
    <PaperContainer>
      <Box px={2}>
        <TopSection image={`${baseImageBucketUrl}${image}`} typeOfBuilding = {typeOfBuilding} projectType = {projectType}/>
        <DetailSection projectType={projectType} location={location} area={area} status={status} description={details?.description} />
         {details?.imageGallery.length > 0 && <Gallery gallery={details?.imageGallery} /> } 
      </Box>
    </PaperContainer>
  );
};

export default ContructionDetails;
