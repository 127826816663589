import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./styles.css";
import { Grid, Navigation, Pagination } from "swiper/modules";
import { UseIsAboveMd, UseIsAboveSm } from "../../helperHooks";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import defaultImage from "src/components/image/Image_not_available.png"

const useStyles = makeStyles((theme) => ({
  card: {
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      height: "200%",
    },
    background: "#fff",
    minWidth: "100%",
    minHeight: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    transition: "background-color 0.5s ease",
    position: "relative",
    "&:hover $cardInfo": {
      opacity: 1,
    },
  },
  cardInfo: {
    cursor: "pointer",
    opacity: 0,
    zIndex: 20,
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    color: "white",
    transition: "opacity 0.5s ease",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
}));

const baseImageBucketUrl = process.env.REACT_APP_S3_IMAGE_BUCKET_URL

const CardComponent = ({ id, classes, data:{projectImage:image, title, location, area, projectStatus:status, projectType, details, typeOfBuilding} }) => {
  const history = useHistory();
  return (
    <div
      className={classes.card}
      style={{ backgroundImage: `url(${baseImageBucketUrl+image || defaultImage})` }}
      onClick={() => history.push(`/construction/${id}`, {id, image, title, location, area, status, projectType, details, typeOfBuilding} )}
    >
      <div className={classes.cardInfo}>
        <Typography>
        {projectType}
        </Typography>
        <Typography>
        {typeOfBuilding} | {area}
        </Typography>
      </div>
    </div>
  );
};

export default function Portfolio({ data }) {
  const isAboveSm = UseIsAboveSm();
  const isAboveMd = UseIsAboveMd();
  const classes = useStyles();

  return (
    <Swiper
      slidesPerView={isAboveSm ? (isAboveMd ? 3 : 2) : 1}
      navigation={true}
      grid={{ rows: isAboveSm ? 2 : 1 }}
      pagination={{ clickable: true }}
      modules={[Grid, Pagination, Navigation]}
      className="mySwiper"
    >
      {data?.map((card, index) => (
        <SwiperSlide key={index}>
          <CardComponent id={card.id} classes={classes} data={card} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}