import useMediaQuery from '@mui/material/useMediaQuery';
import theme from '../components/Theme/theme';

export const UseIsAboveMd = () => {
    const aboveMd = useMediaQuery(theme.breakpoints.up('md'),{noSsr:true})
    return aboveMd
}
export const UseIsAboveSm = () => {
    const aboveSm = useMediaQuery(theme.breakpoints.up('sm'), {noSsr:true});
    return aboveSm
}
