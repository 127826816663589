import React from 'react';
import { Card, CardMedia, CardContent, Typography } from '@mui/material';
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import defaultImage from "src/components/image/Image_not_available.png"


const ConstructionCard = ({ id, projectImage:image, title, location, area, projectStatus:status, projectType, details, typeOfBuilding }) => {
    const history = useHistory();

    const baseImageBucketUrl = process.env.REACT_APP_S3_IMAGE_BUCKET_URL

  return (
    <Card  sx={{
        // maxWidth: 300,
        width:"100%",
        
        cursor: "pointer",
        "&:hover": {
          "& .card-media-image": {
            transform: "scale(1.1)",
          },
        },
        borderRadius: 5,
        boxShadow: "10px 10px 8px rgb(175, 174, 174)",
      }}  onClick={() => history.push(`/construction/${id}`, {image, title, location, area,status, projectType, details,typeOfBuilding}) }>
      <CardMedia
        component="img"
        height="200"
        image={image ? `${baseImageBucketUrl}${image}` : defaultImage}
        alt={title}
      />
      <CardContent>
        <Typography variant="h6" component="div">
          {title}
        </Typography>
        <Typography variant="body2" fontWeight="bold" color="text.secondary">
          {location} | {area} Sq.Ft. | {status}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ConstructionCard;