import { Box, Button, Typography } from "@mui/material";
import sample from "../../Logo/Homely Logo Intro.mp4";
import { makeStyles } from "@mui/styles";

import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles((theme) => ({
  videoContainer: {
    position: "relative",
    // width: "100%",
    height: "100%", /* Full viewport height */
    // objectFit: "unset",
    // [theme.breakpoints.up("md")]: {
    //   height: "50vh",
    // },
    // [theme.breakpoints.up("lg")]: {
    //   height: "60vh",
    // },
  },
  video: {
    width: "100%",
    height: "100%",
    objectFit: "fill",
  },
  textOverlay: {
    position: "absolute",
    top: "30%",
    left: "5%",
    // transform: "translate(-50%, -50%)",
    color: "white",
    fontSize: "24px",
    // textAlign: "center",
    maxWidth: "40%",
    
    // zIndex:"9",
    [theme.breakpoints.down("sm")]:{
      maxWidth:"80%",
      allignSelf:"center",
      fontSize: "12px"
      
    }

    // alignSelf:
  },
  overlayText1: {
    fontSize: "30px",
    [theme.breakpoints.down("sm")]:{
      maxWidth:"80%",
      allignSelf:"center",
      fontSize: "12px"
      
    }
  },
  overlayText2: {
    fontSize: "20px",
    [theme.breakpoints.down("sm")]:{
      maxWidth:"80%",
      allignSelf:"center",
      fontSize: "8px"
      
    }
  },
  getStartedButton:{
    color: 'white',
    borderColor: 'white',
    '&:hover': {
      borderColor: 'black',
      backgroundColor:"black"
      // backgroundColor: 'rgba(255, 255, 255, 0.1)', // Slight white color on hover
    },
  }
}));

const VideoWithTextOverlay = () => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Box className={classes.videoContainer}>
      <video className={classes.video} autoPlay loop muted width={"100%"} style={{ filter: "brightness(50%)" }} >
        <source src={sample} type="video/mp4" />
      </video>
      <Box className={classes.textOverlay}>
        <Typography align="left" className={classes.overlayText1}   >
 
        Crafting yours HOMELY homes 
        </Typography>
        <Typography align="left" className={classes.overlayText2} >
        Take a step towards this journey together.
      </Typography>
        <Box  mt={2} >

        <Button variant="outlined" className={classes.getStartedButton}   onClick={() => {
    const element = document.getElementById("how-homely-works");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }} >
  Get Started
</Button>

        </Box>
        {/* <Typography align="left" >Get Started</Typography> */}
        </Box>
    </Box>
  );
};

export default VideoWithTextOverlay;

