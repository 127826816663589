import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Alert,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";

const ContactDialog = ({ open, handleClose }) => {
  const { state } = useLocation();
  const estate = state.property;

  const PDF_FILE_URL = estate.projectBrochureUrl;

  const [status, setStatus] = useState({ loading: false, success: false });

  const apiIp = process.env.REACT_APP_API_PUBLIC_AP

  const handleSubmit = async (formData) => {
    const { name, email, phone } = formData;
    setStatus({ loading: true, success: false });
    try {
      await axios.post(
        `${apiIp}/api/email/send-email`,
        { email, name, phoneNo: phone, description: "From Brochure" }
      );

      await axios.post(
        `${apiIp}/api/spreedsheet/sheet-data-update`,
        { email, name, phoneNo: phone, description: "From Brochure" }
      );

    } catch (err) {
    } finally {
      setStatus({ loading: false, success: true });
      setTimeout(() => {
        setStatus({ loading: false, success: false });
        window.open(PDF_FILE_URL, "_blank");
        handleClose();
      }, 1000);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: "form",
        onSubmit: (event) => {
          event.preventDefault();
          const formData = new FormData(event.currentTarget);
          const formJson = Object.fromEntries(formData.entries());
          handleSubmit(formJson);
        },
        style: {
          width: "400px",
          height: "450px",
          borderRadius: "20px",
        },
      }}
    >
      <DialogTitle textAlign={"center"}>
        Submit Contact Details To View/Download Brochure
      </DialogTitle>
      <DialogContent>
        {status.success && (
          <Alert severity="success">Form submitted successfully!</Alert>
        )}
        <TextField
          margin="normal"
          id="name"
          name="name"
          required
          fullWidth
          label="Name"
          variant="standard"
        />
        <TextField
          margin="normal"
          id="email"
          name="email"
          required
          fullWidth
          label="Email"
          type="email"
          variant="standard"
        />
        <TextField
          margin="normal"
          id="phone"
          name="phone"
          required
          fullWidth
          label="Phone Number"
          type="tel"
          variant="standard"
        />
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={status.loading}
          onClick={handleClose}
          color="secondary"
        >
          {" "}
          Cancel
        </LoadingButton>
        <LoadingButton
          loading={status.loading}
          type="submit"
        >
          {" "}
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ContactDialog;