import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import brand1 from 'src/Logo/image_brand_card_1.png'; 
import brand2 from 'src/Logo/image_brand_card_2.png'; 
import { UseIsAboveSm } from "src/helperHooks";


const brands = [
  { name: 'Homely', logo: brand1 },
  { name: 'Homely Properties', logo: brand2 },

];


const OurBrand = () => {
  const isAboveSm = UseIsAboveSm();
  return (
    <Box sx={{ flexGrow: 1, textAlign: 'center', p: { xs: 2, md: 5 } }}>
      <Typography variant="h4" gutterBottom py={2}>
        Our Brands
      </Typography>
      <Grid container spacing={3} justifyContent="center">
        {brands.map((brand, index) => (
          <Grid item xs={12} sm={6} md={4} key={index} py={2}>
            <Box
              component="img"
              height={80}
              alt={brand.name}
              src={brand.logo}
              py={isAboveSm ? 0 : 2}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default OurBrand;