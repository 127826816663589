import React from "react";
import { Box, Typography, Grid, Divider, Link } from "@mui/material";
import facebook from "../../Logo/icons8-facebook-16.png";
import x from "../../Logo/icons8-twitterx-16.png";
import insta from "../../Logo/icons8-instagram-16.png";
import { makeStyles } from "@mui/styles";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(() => ({
  clickable: {
    cursor: "pointer",
    display: "inline",
  },
}));

const Footer = () => {
  const history = useHistory();
  const classes = useStyles();

  const email = "info@homelys.com";
  const subject = "Subject Here";
  const body = "Body of the email here";

  const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(
    subject
  )}&body=${encodeURIComponent(body)}`;

  const links = [
    { label: "Construction", path: "/construction" },
    { label: "Interior", path: "/interior" },
    { label: "Real Estate", path: "/real-estate" },
    { label: "About Us", path: "/about-us" },
  ];

  return (
    <Box
      style={{ backgroundColor: "white" }}
      py={3}
      px={1}
      sx={{ px: { xs: "24px", md: "16px", lg: "16px" }}}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="baseline"
        rowGap="16px"
        mb="16px"
      >
        <Grid item xs={12} sm={6} md={3}>
          <Box sx={{ maxWidth: "80%" }}>
            <Typography variant="subtitle2">BUILDING ADDRESS</Typography>
            <Typography variant="subtitle2">
              Old NH-6 Near Bandutikra chowkBargarh, Odisha, 768028
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Box>
            <Typography variant="h6">GET TO KNOW US</Typography>
          </Box>
          <Box>
            {links.map((link) => (
              <Box key={link.path}>
                <Typography
                  onClick={() => history.push(link.path)}
                  variant="body2"
                  className={classes.clickable}
                >
                  {link.label}
                </Typography>
              </Box>
            ))}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Box>
            <Typography variant="h6">NEED HELP?</Typography>
            <Box>
              <Typography variant="body2">Contact Us</Typography>
              <Typography variant="body2">
                <Link
                  href={mailtoLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  info@homelys.com
                </Link>
              </Typography>
              <Typography variant="body2">Phone: +91 9937476041</Typography>
            </Box>
          </Box>
        </Grid>
        {/* Connect section  */}
        <Grid item xs={12} sm={6} md={3}>
          <Box>
            <Typography variant="h6">CONNECT WITH US</Typography>
          </Box>
          <Box display="flex" justifyContent="space-between" width="30%" py={2}>
            <a href="https://www.facebook.com/HOMELYSOLUTION" target="_blank">
              <img src={facebook} alt="Facebook" />
            </a>
            <a href="https://x.com" target="_blank">
              <img src={x} alt="Twitter" />
            </a>
            <a href="https://www.instagram.com/homelys_/#" target="_blank">
              <img src={insta} alt="Instagram" />
            </a>
          </Box>
        </Grid>
      </Grid>
      <Divider color="black" />
      <Box>
        <Typography variant="subtitle2" align="center" py={1}>
          ©{new Date().getFullYear()} Homely. All rights reserved.
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
