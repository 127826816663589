import React, { memo, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import HowWeHomelyWork from "../components/how-we-work/how-we-work";
import Portfolio from "../components/PortfolioSection/PortfolioSection";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import OurBrand from "../components/BrandCard/BrandCard";
import OfferCard from "../components/offer-card/OfferCard";
import dodle1 from "src/components/Logo/Book-With-Us.svg";
import dodle2 from "src/components/Logo/Meet-Our_Architechs.svg";
import dodle3 from "src/components/Logo/Finalize Design.svg";
import dodle4 from "src/components/Logo/Track Your Construction.svg";
import dodle5 from "src/components/Logo/settle in.svg";
import Fmain from "../components/fetured-properties/Fmain";
import HowWeWork from "../components/how-we-work/how-homely-works";
import { UseIsAboveSm } from "../helperHooks";
import HeroSection from "../components/hero-card/HeroSection";
import { useGetConstructionDetails, useGetRealEstateDetails } from "src/helperHooks/getDetails";
import construction from "src/components/image/project_42_home.webp";
import realestate from "src/components/image/project_Image_home.webp";
import interior from "src/components/image/Image-46.webp";

const useStyles = makeStyles((theme) => ({
  portfolioPageContainer: {
    backgroundColor: "black",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    gap: "40px",
    alignItems: "flex-end",
    color: "white",
    padding: "40px 24px",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
      gap: "8px",
      padding: "24px",
    },
  },
  portfolioButton: {
    color: "black",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  offerCard: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
    alignItems: "center",
    justifyContent: "center",
    gap: "40px",
    marginBottom: "32px",
  },
}));

const offerCreds = [
  {
    path: "/construction",
    title: "Construction",
    imageUrl:
      construction,
    description:
      "Homely Buildofiy specializes in providing end-to-end construction solutions for residential, commercial, and industrial projects. From concept to completion, the company ensures meticulous planning, precise execution, and timely delivery.",
  },
  {
    path: "/interior",
    title: "Interior",
    imageUrl:
      interior,
    description:
      "With a team of experienced interior designers and decorators, Homely Buildofiy brings creativity and functionality to interior spaces.Services include space planning, conceptual design, furniture selection, lighting design, material sourcing, and project supervision.",
  },
  {
    path: "/real-estate",
    title: "Real Estate",
    imageUrl:
     realestate,
    description:
      "Homely Buildofiy is involved in the development of residential, commercial, and mixed-use properties, catering to the evolving needs of urban communities.",
  },
];

const homelyWorkSteps = [
  {
    id: "Step 1",
    title: "Book With us",
    description: "Embark on your journey to a dream home with us by simply booking your spot today. Whether you prefer the ease of our website, a quick call, or a friendly chat on WhatsApp, we're here to make your home construction journey as smooth as possible. Let's build something beautiful together",
    image: dodle1,
  },
  {
    id: "Step 2",
    title: "Meet our Architect",
    description:
      "Step into a world where your dreams become architectural masterpieces. At Homely, we don't just build houses, we craft personalized sanctuaries that reflect your essence. Our team of visionary architects and designers blend artistry with innovation to sculpt spaces that resonate with your soul.",
    image: dodle2,
  },
  {
    id: "Step 3",
    title: "Finalize Design ",
    description:
      "Our team designs your dream home based on your preferences, allowing real-time adjustments in during planning. Once the design is finalized, we'll work out a budget to turn your vision into reality",
    image: dodle3,
  },
  {
    id: "Step 4",
    title: "Track Your Construction",
    description:
      "As the construction phase commences, our mobile application keeps you connected. Monitor daily advancements from anywhere, guaranteeing that your dream home is evolving to meet your expectations.",
    image: dodle4
  },
  {
    id: "Step 5",
    title: "Settle In",
    description:
      "After conducting more than 1000 stringent quality checks, we guarantee the delivery of your home within the planned budget and schedule. Presenting you with the keys to your happiness is a source of great pride for us.",
    image: dodle5
  },
];

const HomePage = () => {
  const classes = useStyles();
  const history = useHistory();
  const isAboveSm = UseIsAboveSm();
  const { data } = useGetConstructionDetails();
  const { data: realEstateData, loading } = useGetRealEstateDetails();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box>
      <HeroSection />
      <Box id="how-homely-works">
        {isAboveSm ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              mb: 5,
              paddingTop: 5,
            }}
          >
            <Typography variant="h4" mb={5} sx={{textAlign: "center"}}>
              How Homely Works
            </Typography>
            {homelyWorkSteps.map((step, index) => (
              <HowWeHomelyWork
                key={index}
                index={index}
                title={step.title}
                description={step.description}
                image={step.image}
              />
            ))}
          </Box>
        ) : (
          <Box px={2} my={4}>
            <Typography variant="h4" mb={2} sx={{textAlign: "center"}}>
              How Homely Works
            </Typography>
            <Box style={{ height: "500px", zIndex: "1" }}>
              <HowWeWork />
            </Box>
          </Box>
        )}
      </Box>
      <Box style={{ overflow: "hidden" }}>
        <Typography variant="h4" textAlign={"center"} mb={2}>
          Featured Properties
        </Typography>
        <Fmain data={realEstateData} loading={loading} />
      </Box>
      <Box mt={5}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mb: 5,
          }}
        >
          <Typography variant="h4" mb={5}>
            What We Offer
          </Typography>
          <Box className={classes.offerCard} mx="auto" mb={2}>
            {offerCreds.map((offer, index) => (
              <OfferCard
                key={index}
                index={index}
                title={offer.title}
                imageUrl={offer.imageUrl}
                description={offer.description}
                redirectPath={offer.path}
              />
            ))}
          </Box>
        </Box>
      </Box>
      <Box className={classes.portfolioPageContainer} width={"100%"}>
        <Box>
          <Typography variant="caption">Portfolio</Typography>
          <Typography variant="h5">
            Dreams that we turned into Reality
          </Typography>
        </Box>
        <Button
          className={classes.portfolioButton}
          variant="contained"
          sx={{ backgroundColor: "white", color: "black" }}
          onClick={() => history.push("/construction")}
        >
          Portfolio
        </Button>
      </Box>
      <Box>
        <Portfolio data={data} />
      </Box>
      <Box my={2}>
        <OurBrand />
      </Box>
    </Box>
  );
};

export default memo(HomePage);