import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useGetInteriorDetails } from "src/helperHooks/getDetails";
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
import PaperContainer from "src/components/PaperContainer/PaperContainer";

const InteriorPage = () => {
  const { data } = useGetInteriorDetails();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const isLg = useMediaQuery(theme.breakpoints.down("lg"));

  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getWidth = () => {
    if (isMd) return "90%";
    if (isLg) return "80%";
    return "70%";
  };

  const handleImageClick = (index) => {
    setCurrentImage(index);
    setLightboxOpen(true);
  };

  const baseImageBucketUrl = process.env.REACT_APP_S3_IMAGE_BUCKET_URL

  return (
    // <PaperContainer>
      <Box
        px={2}
        my={3}
        style={{
          display: "flex",
          alignItems: "center",
          minHeight: "60vh",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          style={{ fontFamily: "monospace" }}
        >
          {data?.[0].title}
        </Typography>
        <Typography variant="body1" align="center" paragraph>
          Elevate your living and working spaces with our bespoke interior
          solutions. From conceptualization to execution, our interior design
          services add a touch of sophistication and functionality to every
          environment.
        </Typography>
        <Grid container spacing={2} width={getWidth()}>
          {data?.[0].interiorGallary.map((el, index) => (
            <Grid item xs={12} sm={6} lg={4} key={index}>
              <img
                src={`${baseImageBucketUrl}${el}`}
                style={{ width: "100%", cursor: "pointer" }}
                onClick={() => handleImageClick(index)}
              />
            </Grid>
          ))}
        </Grid>
        {lightboxOpen && (
          <Lightbox
            images={data?.[0].interiorGallary.map((src) => ({ url: `${baseImageBucketUrl}${src}` }))}
            startIndex={currentImage}
            onClose={() => setLightboxOpen(false)}
          />
        )}
      </Box>
    // </PaperContainer>
  );
};
export default InteriorPage;
