import { createTheme, responsiveFontSizes } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: 'sans-serif',
  },
  listItemText: {
     fontFamily: 'sans-serif'
  },
  CardHeader: {
    fontFamily: 'sans-serif'
  }
});

export default responsiveFontSizes(theme);