import React from "react";
import {
  CardContent,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";

const HowWeHomelyWork = ({ index, title, description, image }) => {
  const isEven = index % 2 === 0;

  const isDesktop = useMediaQuery("(min-width:600px)");

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const slideInVariants = {
    hidden: { opacity: 0, x: isEven ? -300 : 300 },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        type: "spring",
        duration: 0.5,
        ease: "easeOut",
      },
    },
  };

  return (
    <motion.div
      ref={ref}
      variants={isDesktop ? slideInVariants : null}
      initial="hidden"
      animate={inView ? "visible" : "hidden"}
    >
      <Paper elevation={0} sx={{ maxWidth: 700, margin: "2 auto" }}>
        <CardContent>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6} md={6} order={isEven ? 2 : 1}>
              <img
                src={image}
                alt={title}
                style={{
                  width: "100%",
                  objectFit: "cover",
                  borderRadius: isEven ? "0 8px 8px 0" : "8px 0 0 8px",
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              // order={isEven ? 1 : 2}
              sx={{ order: { sm: isEven ? 1 : 2 } }}
              // textAlign={isEven ? "right" : "left"}
              textAlign="center"
            >
              <Typography variant="h5" component="div">
                {title}
              </Typography>
              <Typography variant="body1" component="div">
                {description}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Paper>
    </motion.div>
  );
};

export default HowWeHomelyWork;
